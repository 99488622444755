<template>
    <div class="modal profile_modal suppresion_list" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0">Suppression List</h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
                <ul class="tab_row">
                    <li @click="tab = 'bounces'" :class="{'active': tab === 'bounces'}">Bounces</li>
                    <li @click="tab = 'complaints'" :class="{'active': tab === 'complaints'}">Complaints</li>
                    <li @click="tab = 'unsubscribes'" :class="{'active': tab === 'unsubscribes'}">Unsubscribes</li>
                </ul>
            </div>
            <div class="modal_body">
                <div v-show="tab === 'bounces'" class="mt-3">
                    <supperession-bounce :tab="tab" :broadcast-id="broadcastId" />
                </div>
                <div v-show="tab === 'complaints'" class="mt-3">
                    <supperession-complaint :tab="tab" :broadcast-id="broadcastId" />
                </div>
                <div v-show="tab === 'unsubscribes'" class="mt-3">
                    <supperession-unsubscribe :tab="tab" :broadcast-id="broadcastId" />
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" @click="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'

    const SupperessionBounce = defineAsyncComponent(() => import('@/pages/broadcast/components/SupperessionBounce'))
    const SupperessionComplaint = defineAsyncComponent(() => import('@/pages/broadcast/components/SupperessionComplaint'))
    const SupperessionUnsubscribe = defineAsyncComponent(() => import('@/pages/broadcast/components/SupperessionUnsubscribe'))

    export default {
        name: 'Suppression List',

        data () {
            return {
                tab: 'bounces',
            };
        },

        props: {
            modelValue: Boolean,
            broadcastId: [String, Number],
        },

        emits: ['update:modelValue'],

        components: {
            SupperessionBounce,
            SupperessionComplaint,
            SupperessionUnsubscribe,
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },
        },
    }
</script>

<style scoped>
    .suppresion_list .modal_container {
        height: 640px;
        background: #f5f5f5;
    }

    .modal.suppresion_list .modal_header {
        padding: 20px 30px 0;
    }

    .modal.suppresion_list .modal_header .close_btn {
        left: auto;
    }

    .modal.suppresion_list .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .suppresion_list .tab_row {
        padding: 20px 0 0 0;
        display: flex;
        gap: 30px;
    }

    .suppresion_list .tab_row li {
        padding-bottom: 12px;
        position: relative;
        cursor: pointer;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
        overflow: hidden;
    }
    .suppresion_list .tab_row li:after {
        position: absolute;
        content: '';
        left: -100%;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid #2F80ED;
        transition: all 0.3s ease-in-out;
    }

    .suppresion_list .tab_row li.active {
        color: #2f7eed;
    }
    .suppresion_list .tab_row li.active:after{
        left: 0;
    }

    :deep(.result_wpr table td:first-child),
    :deep(.result_wpr table th:first-child) {
        padding: 0;
        width: auto;
        text-align: left;
    }
    @media(max-width: 767px){
        .suppresion_list .tab_row li{
            font-size: 13px;
            padding: 8px 15px;
        }
    }
    @media(max-width: 499px){
        .suppresion_list .tab_row li{
            font-size: 11px;
            padding: 8px 15px;
        }
    }
</style>
